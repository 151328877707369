import React, { useState, useEffect, useRef } from 'react';
import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { BusinessData, MutationArguments, PageSchema, Table } from './types';
import { TRACES_TABLE, UPLOAD_TABLE, CUSTOMLIST_TABLE } from './constants';
import CardWrapper from 'components/CardWrapper';
import withCommonNetworkFailure from '../../components/HOC/withCommonNetworkFailure';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from '../../containers/GenericModal/reducer';
import FilterBuilder from '../../lib/builders/FilterBuilder';
import AbstractResourceFactory from './helpers/factories/resource/abstract-resource-factory';
import getResourceFactory from './helpers/factories/resource/get-resource-factory';
import actionHandler from './helpers/actionHandler';
import checkTableIsApiPaginated from './helpers/checkTableIsApiPaginated';
import handleSubTitle from './helpers/handleSubTitle';
import getHeaderAction from './helpers/getHeaderAction';
import {
  useEditMutation,
  useAddMutation,
  useResendOneMutation,
  useResendAllMutation,
  useValidateOneMutation,
  useValidateAllMutation,
  useEditStatusMutation,
} from './helpers/mutations';
import mutationGeneration from './helpers/mutationGeneration';
import qrParametersGeneration from './helpers/qrParametersGeneration';
import { filterNonEmptyObjectValues, to_query } from 'lib/util';
import errorHandler from 'lib/errorHandler';

const FrontEndTableComponent = React.lazy(
  () => import('./table/front-end-paginated-table')
);
const BackEndTableComponent = React.lazy(
  () => import('./table/back-end-paginated-table')
);

type Props = {
  pageName: string;
  user: any;
  pageSchema: PageSchema;
  filter_params: any;
  business: BusinessData;
  traceId: string;
  withWrapper?: boolean;
  isSearchBarEnabled?: boolean;
  searchPlaceholder?: string;
  searchButtonText?: boolean;
  preFilters?: any;
  exportAllButtonOptions?: {
    customExportPath?: string | string[];
    customHeaderExportKeys?: string[];
    extraColumns?: any;
    exportDashboardTableData?: boolean;
    customExportFilename?: string;
  };
};

const GenericTablePage: React.FC<Props> = ({
  pageName,
  user,
  pageSchema,
  filter_params,
  business,
  traceId,
  withWrapper = true,
  isSearchBarEnabled = true,
  searchPlaceholder,
  searchButtonText,
  preFilters,
  exportAllButtonOptions,
}: Props) => {
  const resourceFactory: AbstractResourceFactory = getResourceFactory(
    pageSchema?.component || pageName,
    { pageSchema }
  );

  const filter_builder = new FilterBuilder();
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(1);
  const [searchField, setSearchField] = useState<string>('');
  const [filter, setFilter] = useState<object | any>(() => {
    const defaultFilterState = resourceFactory.defaultFilterState || {};
    return { ...defaultFilterState };
  });
  const preFiltersRef = useRef(preFilters);
  const { t } = useTranslation();
  const language = useSelector(
    (state: any) => state.auth?.currentUser?.attributes?.lang
  );

  useEffect(() => {
    preFiltersRef.current = preFilters;
  }, [preFilters]);

  useEffect(() => {
    const defaultFilterState = resourceFactory.defaultFilterState || {};

    if (
      preFilters &&
      typeof preFilters === 'object' &&
      Object.keys(preFilters).length > 0
    ) {
      setFilter((prevFilter) => {
        const decodedPrevFilters = decodeURIComponent(prevFilter.filters);
        
        const currentFilters = {
          ...JSON.parse(decodedPrevFilters),
          ...preFilters,
        };
        const encodedFilters = to_query(currentFilters);

        return {
          ...defaultFilterState,
          filters: encodedFilters,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilters]);

  const path = pageSchema?.path?.split('/')[1];
  const isSearchEnabled = resourceFactory?.hasOwnProperty('isSearchEnabled')
    ? resourceFactory?.isSearchEnabled
    : isSearchBarEnabled;
  const apiPaginatedTable = checkTableIsApiPaginated(pageName, pageSchema);
  const uploadType = filter_params?.upload_type || null;

  const { data, isFetching }: QueryObserverResult<Table, any> = useQuery(
    [pageName, page, searchField, filter],
    () => {
      return resourceFactory.getAll(
        page,
        searchField,
        filter,
        path,
        filter_params,
        pageSchema,
        traceId,
        business,
      );
    },
    {
      cacheTime: 300,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => errorHandler(error),
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries([pageName, page, searchField, filter]);
  }, [language, queryClient, pageName, page, searchField, filter]);

  const refreshFunc = async () =>
    await queryClient.refetchQueries([pageName, page, searchField, filter]);

  const handleAccessCreation = (tableName, user, uploadType) => {
    const { steps, role, object_permissions, business } = user;

    if (role === 'Admin') return true;

    switch (tableName) {
      case TRACES_TABLE:
        return steps.length > 0;
      case UPLOAD_TABLE:
        return uploadType === 'object'
          ? !business.object_permissions_enabled ||
              object_permissions.length > 0
          : steps.length > 0;
      case CUSTOMLIST_TABLE:
        return data?.allow_creation;
      default:
        return true;
    }
  };

  const mutationArguments: MutationArguments = {
    factory: resourceFactory,
    apiPaginatedTable,
    client: queryClient,
    name: pageName,
    page,
    searchField,
    filter,
  };

  // Mutations List
  const { mutate: editMutation } = useEditMutation(mutationArguments);
  const { mutate: addMutation } = useAddMutation(mutationArguments);
  const { mutate: resendOneMutation } = useResendOneMutation(mutationArguments);
  const { mutate: resendAllMutation } = useResendAllMutation(mutationArguments);
  const { mutate: validateOneMutation } =
    useValidateOneMutation(mutationArguments);
  const { mutate: validateAllMutation } =
    useValidateAllMutation(mutationArguments);
  const { mutate: editStatusMutation } =
    useEditStatusMutation(mutationArguments);

  const mutations = {
    addMutation,
    editMutation,
    resendOneMutation,
    resendAllMutation,
    validateOneMutation,
    validateAllMutation,
    editStatusMutation,
  };

  const actionGeneration = (action: string, record: object, errorMessage?: string, historyObjectId?: number | string) => {
    const mutation = mutationGeneration(action, mutations);

    return actionHandler(action, mutation, record, {
      path,
      filter_params,
      user,
      handleSubTitle,
      filters: filter,
      searchField,
      url: qrParametersGeneration(record, action, business as BusinessData).url,
      imageName: qrParametersGeneration(
        record,
        action,
        business as BusinessData
      ).imageName,
      allData: data?.data,
      objectType: data?.object_type,
      chainTableName: pageSchema?.options?.name || '', // TODO Replace with `optionName` field if `...options.name` will be used for the not only Chains table
      optionName: pageSchema?.options?.name || '',
      historyObjectId,
      formSchema: data?.formSchema,
      errorMessage,
      t,
    });
  };

  const handleTableChange = (_pagination, filters, sorter) => {
    const { field, order } = sorter;
    const currentPreFilters = preFiltersRef.current;
    const currentFilters = filterNonEmptyObjectValues(filters);

    setPage(1);

    const allFilters =
      pageName === UPLOAD_TABLE || pageSchema?.component === UPLOAD_TABLE
        ? {
            ...(currentPreFilters || {}),
            ...currentFilters,
            upload_type: filter_params?.upload_type || 'trace',
          }
        : {
            ...(currentPreFilters || {}),
            ...currentFilters,
          };

    if (pageName !== TRACES_TABLE) {
      setFilter(
        filter_builder.get_filter({
          filters: allFilters,
          sorter,
          filter,
        })
      );
    } else {
      setFilter({
        sortingField: field || filter.sortingField,
        sortDirection: order || filter.sortDirection,
        filters: to_query(filters),
      });
    }
  };

  let Table: any;
  if (apiPaginatedTable) {
    Table = BackEndTableComponent;
  } else {
    Table = FrontEndTableComponent;
  }

  const table = (
    <React.Suspense
      fallback={<Spin style={{ display: 'block' }} size="large" />}
    >
      {data ? (
        <Table
          dataSource={data?.data}
          columns={data?.schema}
          isAllowed={handleAccessCreation(
            pageSchema?.component || pageName,
            user,
            uploadType
          )}
          pagination={!apiPaginatedTable ? { showSizeChanger: false } : false}
          handleTableChange={apiPaginatedTable ? handleTableChange : undefined}
          isLoading={isFetching}
          getRowKey={(record: any) =>
            `${
              record.traceRecordId || record.traceId || record.id
            }_${Math.random().toFixed(5)}`
          }
          onRowAction={({
            record,
            action,
            errorMessage,
            historyObjectId,
          }: {
            record: object;
            action: string;
            errorMessage?: string;
            historyObjectId?: string | number;
          }) => actionGeneration(action, record, errorMessage, historyObjectId)}
          actions={getHeaderAction(pageName, pageSchema, business?.name, t)}
          actionHandler={(action: string) => actionGeneration(action, {})}
          searchField={searchField}
          setSearchField={setSearchField}
          page={page}
          setPage={setPage}
          isSearchEnabled={isSearchEnabled}
          searchPlaceholder={searchPlaceholder}
          searchButtonText={searchButtonText}
          apiPaginatedTable={apiPaginatedTable}
          total={data?.total}
          list_name={path}
          exportAllButtonOptions={exportAllButtonOptions}
          filter={filter}
          historyObjectId={traceId}
          pageSchema={pageSchema}
        />
      ) : (
        <Spin style={{ display: 'block' }} size="large" />
      )}
    </React.Suspense>
  );

  return withWrapper ? (
    <CardWrapper pageName={pageName} refreshFunc={refreshFunc}>
      {table}
    </CardWrapper>
  ) : (
    <>{table}</>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: { attributes },
  },
}) => ({ user: attributes, business: attributes.business });

const mapDispatchToProps = {
  openGenericModal: openGenericModalAction,
  closeGenericModal: closeGenericModalAction,
};

const composeResult = compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withCommonNetworkFailure
)(GenericTablePage);

export default composeResult;
