import { FC } from 'react';
import { Space } from 'antd';
import Badge from 'components/Badge';
import { SContainer } from './styled-components';

interface CategoryOverviewProps {
  categories: {
    title: string;
    description: string;
    badge?: boolean;
    badgeBackgroundColor?: string;
  }[];
  styles: any;
}

const CategoryOverview: FC<CategoryOverviewProps> = ({
  categories,
  styles,
}) => {
  return (
    <SContainer styles={styles}>
      <div className="categories-wrapper">
        {categories?.map((category, index) => (
          <Space key={`${category}-${index}`} className="category-item">
            {category.badge ? (
              <Badge
                title={category.title}
                customColor={{
                  background: category.badgeBackgroundColor,
                  text: '#000',
                }}
              />
            ) : (
              <>{category.title}</>
            )}
            {category.description}
          </Space>
        ))}
      </div>
    </SContainer>
  );
};

export default CategoryOverview;
