import styled from 'styled-components';
import { font } from 'App/styleVariables';

interface Block {
  styles: {
    minHeight?: string;
    padding?: string;
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

interface LegendItemStyles {
  $color: string;
  $labelColor?: string;
}

export const SCardWrapper = styled.div<Block>`
  grid-column-start: ${({ styles }) => styles?.size?.column?.start};
  grid-column-end: ${({ styles }) => styles?.size?.column?.end};
  grid-row-start: ${({ styles }) => styles?.size?.row?.start};
  grid-row-end: ${({ styles }) => styles?.size?.row?.end};
  box-sizing: border-box;
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ styles, theme }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  position: relative;
  min-height: ${({ styles }) => styles?.minHeight};

  .ant-flex {
    font-family: inherit;
  }

  .title-block {
    width: 25%;

    h3 {
      font-family: inherit;
      font-size: 20px;
      font-weight: 500;
    }

    .total {
      font-family: inherit;
      margin-bottom: 0;
      font-size: 50px;
      line-height: 1.2;
      font-weight: 600;
    }
  }

  .progress-block {
    width: 75%;

    .segmented-filters {
      margin-bottom: 20px;
    }
  }

  .legend {
    margin-top: 20px;
  }
`;

export const SLegendItem = styled.div<LegendItemStyles>`
  display: flex;
  color: ${({ $labelColor, $color }) => $labelColor || $color};
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1;

  &:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
    margin-right: 5px;
  }

  span {
    line-height: 1;
  }
`;
