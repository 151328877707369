import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

interface Block {
  download?: boolean;
  $bordered?: boolean;
  styles: {
    backgroundColor?: string;
    padding?: string;
    boxShadow?: string;
    title?: {
      color: string;
      fontSize: string;
      fontWeight: string | number;
      textTransform: string;
      textAlign: string;
      lineHeight?: string;
    };
    subtitle?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string | number;
      textTransform?: string;
      textAlign?: string;
      fontStyle?: string;
    };
    header?: {
      justifyContent: string;
      marginTop: string;
    };
    indicators?: {
      color: string;
      fontSize: string;
      textAlign: string;
      fontWeight: string;
      marginRight: string;
    };
    downloadBtn?: {
      colorPassive: string;
      colorActive: string;
      backgroundPassive: string;
      backgroundActive: string;
      borderPassive: string;
      borderActive: string;
      fontSize: string;
      fontWeight: string | number;
      textTransform: string;
    };
    search?: {
      position: string;
      top: number;
      right: number;
      backgroundPassive: string;
      backgroundActive: string;
      colorPassive: string;
      colorActive: string;
      borderPassive: string;
      borderActive: string;
      inputBorder: string;
      inputBackground?: string;
      marginBottom?: string;
    };
    table?: {
      thBackground?: string;
      small?: boolean;
      borderRadius?: string;
      boxShadow?: string;
      wrapper?: {
        boxShadow?: string;
      };
      head?: {
        fontSize: string;
        color: string;
        whiteSpace: string;
        actionPassive: string;
        actionActive: string;
        border: string;
        borderInlineEnd?: string;
        padding?: string;
      };
      body?: {
        minHeight?: string;
        border?: string;
        background?: string;
        borderRadius?: string;
        rowPadding?: string;
        fontSize?: string;
      };
      pagination?: {
        fontSize: string;
        minWidth: string;
        height: string;
        color: string;
        padding: string;
        activeItemColor: string;
        activeItemBackground: string;
        arrowsColor: string;
        arrowMinWidth: string;
        arrowsFontSize: string;
        margin?: string;
      };
      link?: {
        fontSize?: string;
        color?: string;
        display?: string;
      };
    };
    footer?: {
      justifyContent: string;
      description?: {
        color?: string;
        fontSize: string;
        fontWeight: string | number;
        textTransform: string;
        textAlign: string;
        fontStyle?: string;
      };
    };
    spin?: {
      backgroundColor?: string;
      borderRadius?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledTableWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  border-top-right-radius: ${({ styles }) =>
    styles?.table?.borderRadius || '10px'};
  border-top-left-radius: ${({ styles }) =>
    styles?.table?.borderRadius || '10px'};
  border-bottom-left-radius: ${({ styles }) => styles?.table?.borderRadius};
  border-bottom-right-radius: ${({ styles }) => styles?.table?.borderRadius};
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  color: #ffffff;
  text-align: center;
  box-shadow: ${({ styles }) => styles?.boxShadow || styles?.table?.boxShadow};
  background-color: ${({ theme, styles }) =>
    styles?.backgroundColor || theme?.dashboard?.block?.background};
  padding: ${({ styles }) => styles?.padding};

  .ant-spin {
    background-color: ${({ styles }) => styles?.spin?.backgroundColor};
    border-radius: ${({ styles }) => styles?.spin?.borderRadius};
    padding: 10px;
  }

  .header {
    display: flex;
    flex-flow: row wrap;
    justify-content: ${({ styles }) =>
      styles?.header?.justifyContent || 'space-between'};
    align-items: center;
    margin-top: ${({ styles }) => styles?.header?.marginTop || 0};
    margin-bottom: 15px;
    line-height: 35px;

    .title-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 0 !important;
    }

    .title {
      color: ${({ styles }) => styles?.title?.color || '#000000'};
      font-size: ${({ styles }) => styles?.title?.fontSize || '18px'};
      font-weight: ${({ styles }) => styles?.title?.fontWeight || '600'};
      text-align: ${({ styles }) => styles?.title?.textAlign || 'center'};
      text-transform: ${({ styles }) => styles?.title?.textTransform || 'none'};
      line-height: ${({ styles }) => styles?.title?.lineHeight};
      margin-bottom: 0;
    }
    .subtitle {
      color: ${({ styles }) => styles?.subtitle?.color || '#000000'};
      font-size: ${({ styles }) => styles?.subtitle?.fontSize || '14px'};
      font-weight: ${({ styles }) => styles?.subtitle?.fontWeight || '400'};
      text-transform: ${({ styles }) =>
        styles?.subtitle?.textTransform || 'initial'};
      font-style: ${({ styles }) => styles?.subtitle?.fontStyle};
      margin-bottom: 0;
    }

    .title-icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;

      @media (max-width: 1550px) {
        width: 35px;
        height: 35px;
        margin-right: 5px;
      }
    }

    .indicators-wrapper {
      color: ${({ styles }) => styles?.indicators?.color || '#000000'};
      font-size: ${({ styles }) => styles?.indicators?.fontSize || '14px'};
      text-align: ${({ styles }) => styles?.indicators?.textAlign || 'left'};
      font-weight: ${({ styles }) => styles?.indicators?.fontWeight || '400'};
      margin-right: ${({ styles }) => styles?.indicators?.marginRight};

      p {
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 1.2;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .download-btn,
  .deactivate-btn {
    display: flex;
    align-items: center;
    min-width: 32px;
    width: auto;
    height: 32px;
    font-size: ${({ styles }) => styles?.downloadBtn?.fontSize || '14px'};
    font-weight: ${({ styles }) => styles?.downloadBtn?.fontWeight || '600'};
    text-transform: ${({ styles }) =>
      styles?.downloadBtn?.textTransform || 'none'};
    border-radius: 10px !important;
    padding: 5px 10px !important;
    color: ${({ styles }) => styles?.downloadBtn?.colorPassive || '#000000'};
    background: ${({ styles }) =>
      styles?.downloadBtn?.backgroundPassive || '#ffffff'};
    border-color: ${({ styles }) =>
      styles?.downloadBtn?.borderPassive || '#d9d9d9'};

    &:hover {
      color: ${({ styles }) =>
        styles?.downloadBtn?.colorActive || colors.secondary} !important;
      background: ${({ styles }) =>
        styles?.downloadBtn?.backgroundActive || '#ffffff'} !important;
      border-color: ${({ styles }) =>
        styles?.downloadBtn?.borderActive || colors.secondary} !important;
    }

    .anticon-download {
      font-size: 20px;
    }

    .info-icon {
      margin-left: 4px;

      .anticon-info-circle {
        font-size: 15px;
      }
    }
  }

  .table-header {
    box-shadow: none;
    margin-bottom: ${({ styles }) => styles?.search?.marginBottom || 0};
    height: auto;

    & > div {
      background: transparent;
      padding: 0 !important;
      height: auto !important;
      display: flex;
      justify-content: flex-end;
      position: ${({ styles }) => styles?.search?.position};
      top: ${({ styles }) => `${styles?.search?.top}px`};
      right: ${({ download, styles }) =>
        download
          ? `${styles?.search?.right + 120}px`
          : `${styles?.search?.right}px`};

      .ant-input-search {
        width: 20% !important;
        min-width: 150px;

        .ant-input-wrapper {
          background: ${({ styles }) =>
            styles?.search?.inputBackground || 'transparent'} !important;
          border: ${({ styles }) =>
            styles?.search?.inputBorder || '1px solid #d9d9d9'} !important;
          padding-right: 0 !important;
          display: flex;
          border-radius: 10px;

          .ant-input {
            border: 0;
            max-width: unset !important;
            width: calc(100% - 35px);
            background: transparent;
            border-color: transparent;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              font-weight: 400 !important;
            }
            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              font-weight: 400 !important;
            }
            &::-ms-input-placeholder {
              /* Microsoft Edge */
              font-weight: 400 !important;
            }
            &:focus {
              box-shadow: none !important;
            }
          }
          .ant-input-search-button {
            width: 36px !important;
            height: 36px !important;
            border-width: 1px;
            border-style: solid;
            border-color: ${({ styles }) =>
              styles?.search?.borderPassive || 'transparent'};
            background: ${({ styles }) =>
              styles?.search?.backgroundPassive || 'transparent'} !important;
            color: ${({ styles }) =>
              styles?.search?.colorPassive || '#808080'} !important;
            border-radius: 9px !important;
            margin-left: 0;

            &:hover {
              background: ${({ styles }) =>
                styles?.search?.backgroundActive} !important;
              color: ${({ styles }) => styles?.search?.colorActive} !important;
              border-color: ${({ styles }) =>
                styles?.search?.borderActive || 'transparent'};
            }
          }
        }
      }
    }
  }

  .table-wrapper {
    box-shadow: ${({ styles }) => styles?.table?.wrapper?.boxShadow || 'none'};
  }

  .table-container {
    background: ${({ styles }) =>
      styles?.table?.body?.background || 'transparent'} !important;

    .ant-table.ant-table-small {
      border: none;
    }
  }
  .ant-table {
    background: transparent;

    table {
      border-radius: 0;
    }

    .ant-table-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }
    .ant-table-container {
      border: ${({ styles, theme }) =>
        styles?.table?.body?.border || theme?.dashboard?.table?.body?.border};
      border-radius: ${({ styles }) =>
        styles?.table?.body?.borderRadius || '10px'};
      overflow: hidden;
    }
    .ant-table-thead > tr > th {
      font-size: ${({ styles, theme }) =>
        styles?.table?.head?.fontSize || '14px'} !important;
      line-height: 1.2;
      padding: ${({ styles }) =>
        styles?.table?.head?.padding || '8px 8px'} !important;
      border-bottom: ${({ $bordered, styles }) =>
        $bordered
          ? styles?.table?.head?.border || '1px solid #f0f0f0'
          : 'none'} !important;
      border-inline-end: ${({ $bordered, styles }) =>
        $bordered
          ? styles?.table?.head?.borderInlineEnd || styles?.table?.head?.border
          : 'none'} !important;
      background-color: ${({ theme, styles }) =>
        styles?.table?.thBackground ||
        theme?.dashboard?.block?.background ||
        '#ffffff'} !important;
      color: ${({ styles, theme }) =>
        styles?.table?.head?.color ||
        theme?.dashboard?.block?.color ||
        '#000000'} !important;

      &:last-of-type {
        border-inline-end: none !important;
      }

      .ant-table-column-sorters {
        padding-right: 0;

        .ant-table-column-sorter {
          color: ${({ styles }) =>
            styles?.table?.head?.actionPassive || '#bfbfbf'};
          right: -4px !important;
          top: 50% !important;
          transform: translate(0%, -50%);

          &:hover {
            color: ${({ styles }) =>
              styles?.table?.head?.actionActive || '#969595'};
          }
        }

        .ant-table-column-title > div {
          white-space: ${({ styles }) =>
            styles?.table?.head?.whiteSpace || 'break-spaces'} !important;
          word-break: normal !important;
          overflow-wrap: initial;
        }
      }
      .ant-table-filter-column {
        .ant-table-filter-trigger {
          color: ${({ styles }) =>
            styles?.table?.head?.actionPassive || '#bfbfbf'};

          &:hover {
            color: ${({ styles }) =>
              styles?.table?.head?.actionActive || '#969595'};
          }

          &.active {
            color: ${({ theme }) =>
              theme.table.sorterArrow.activeArrow || colors.primary};
          }
        }
      }
    }
    .ant-table-thead td.ant-table-cell-scrollbar {
      background-color: ${({ styles, theme }) =>
        styles?.table?.thBackground ||
        theme?.dashboard?.block?.background ||
        '#ffffff'} !important;
    }
    .ant-table-content {
      padding: 10px !important;
    }
    .ant-table-body {
      padding: 0 !important;

      & > table {
        min-height: ${({ styles }) => styles?.table?.body?.minHeight};
      }
      .ant-table-tbody {
        tr:not(.ant-table-measure-row) td {
          font-size: ${({ styles }) =>
            styles?.table?.body?.fontSize || '11px'} !important;
          padding: ${({ styles }) =>
            styles?.table?.body?.rowPadding || '8px 4px'} !important;
        }
      }
      .ant-empty {
        margin: ${({ styles }) => styles?.table?.small && 0};

        .ant-empty-image {
          display: ${({ styles }) => styles?.table?.small && 'none'};
        }
        .ant-empty-description {
          font-size: ${({ styles }) => styles?.table?.small && '12px'};
        }
      }

      .badge {
        margin: 0 auto;
      }
    }
    [data-name^='row-action'] {
      display: ${({ styles }) => styles?.table?.link?.display || 'block'};
      font-size: ${({ styles }) =>
        styles?.table?.link?.fontSize || '11px'} !important;
      color: ${({ theme, styles }) =>
        styles?.table?.link?.color ||
        theme?.dashboard?.block?.linkColor ||
        '#2697ff'} !important;
    }
  }
  .ant-pagination.ant-table-pagination {
    margin: ${({ styles }) =>
      styles?.table?.pagination?.margin || '15px 0 0'} !important;

    li {
      margin: 0 2px !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: ${({ styles }) => styles?.table?.pagination?.arrowMinWidth};
      margin: 0 !important;
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 20px;
    }

    .ant-pagination-item {
      min-width: ${({ styles }) =>
        styles?.table?.pagination?.minWidth} !important;
      height: ${({ styles }) => styles?.table?.pagination?.height} !important;

      a {
        color: ${({ styles, theme }) =>
          styles?.table?.pagination?.color ||
          theme?.dashboard?.block?.color ||
          '#000000'} !important;
        font-size: ${({ styles }) =>
          styles?.table?.pagination?.fontSize || '#000000'} !important;
        line-height: ${({ styles }) =>
          styles?.table?.pagination?.height} !important;
        padding: ${({ styles }) => styles?.table?.pagination?.padding};
      }
    }
    .ant-pagination-item-active {
      background: ${({ styles }) =>
        styles?.table?.pagination?.activeItemBackground} !important;

      a {
        color: ${({ styles }) =>
          styles?.table?.pagination?.activeItemColor || '#ffffff'} !important;
      }
    }
    .ant-pagination-item-link {
      color: ${({ styles }) =>
        styles?.table?.pagination?.arrowsColor || '#000000'} !important;
      font-size: ${({ styles }) =>
        styles?.table?.pagination?.arrowsFontSize} !important;
    }
  }
  .footer {
    display: flex;
    justify-content: ${({ styles }) =>
      styles?.footer?.justifyContent || 'flex-start'};
    margin-top: 6px;
  }

  .footer-description {
    margin: 0;
    color: ${({ styles }) => styles?.footer?.description?.color || '#000000'};
    font-size: ${({ styles }) =>
      styles?.footer?.description?.fontSize || '12px'};
    font-weight: ${({ styles }) =>
      styles?.footer?.description?.fontWeight || '400'};
    text-transform: ${({ styles }) =>
      styles?.footer?.description?.textTransform || 'initial'};
    font-style: ${({ styles }) => styles?.footer?.description?.fontStyle};
  }
`;

export default StyledTableWrapper;
