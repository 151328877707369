import DashboardDescription from './DashboardDescription';
import ButtonDownload from './ButtonDownload';
import UnmergedStatistic from './UnmergedStatistic';
import ProgressCircles from './ProgressCircles';
import Carousel from './Carousel';
import CircleImagePreview from './CircleImagePreview';
import CircleVideoLink from './CircleVideoLink';
import CustomStatistic from './CustomStatistic';
import FrontendPaginatedTable from './Table/FrontendPaginatedTable';
import BackendPaginatedTable from './Table/BackendPaginatedTable';
import NavigationButton from './NavigationButton';
import FiltersBlock from './FiltersBlock';
import Card from './Card';
import SectionText from './SectionText';
import CustomFilter from './CustomFilter';
import Map from './Map';
import BarChart from './BarChart';
import TabsCard from './TabsCard';
import Image from './Image';
import DescriptionCard from './DescriptionCard';
import StatisticCardWithProgressBar from './StatisticCardWithProgressBar';
import CategoryOverview from './CategoryOverview';

export default {
  DashboardDescription,
  ButtonDownload,
  UnmergedStatistic,
  ProgressCircles,
  Carousel,
  CircleImagePreview,
  CircleVideoLink,
  CustomStatistic,
  FrontendPaginatedTable,
  BackendPaginatedTable,
  NavigationButton,
  FiltersBlock,
  Card,
  SectionText,
  CustomFilter,
  Map,
  BarChart,
  TabsCard,
  Image,
  DescriptionCard,
  StatisticCardWithProgressBar,
  CategoryOverview,
};
