import styled from 'styled-components';
import { Table } from 'antd';
import { borderRadius, blockShadow, colors } from 'App/styleVariables';

const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.table.background} !important;
  border-radius: ${borderRadius};
  box-shadow: ${blockShadow};

  .ant-table {
    border-radius: ${borderRadius} !important;

    table {
      table-layout: fixed !important;
      border-radius: inherit !important;
    }
  }

  tr {
    white-space: ${(props) =>
      props.isBrokenTitle && props.theme.table.brokenTitle
        ? props.theme.table.brokenTitle.whiteSpace
        : 'nowrap'};

    div:not(.ant-table-filter-column) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ant-table-column-sort {
      background-color: transparent;
    }
  }

  th {
    padding: 10px 10px !important;
    /* height: 56px !important; */
    word-break: normal;
    font-size: ${({ isBrokenTitle, theme }) =>
      isBrokenTitle
        ? theme.table.brokenTitle && theme.table.brokenTitle.size
        : theme.table.th.size};
    font-weight: ${({ theme }) => theme.table.th.weight} !important;
    font-family: ${({ theme }) => theme.table.th.family} !important;
    color: ${({ theme }) => theme.table.th.color} !important;
    border-bottom: ${({ theme }) =>
      theme.table.th.border || '1px solid #EAECF0'} !important;
    background-color: ${({ theme }) =>
      theme.table.th.backgroundColor || '#F9FAFB'} !important;
  }

  th:hover .custom-table-sorters {
    .sort-arrow:not(.active) {
      svg {
        fill: ${({ theme }) => `${theme.table.sorterArrow.active} !important`};
      }
    }
  }

  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-thead > tr > th {
    .ant-table-filter-column {
      display: flex;
      justify-content: flex-start;

      .ant-table-column-sorters {
        padding-right: 0;
      }
    }

    .ant-table-column-sorters {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .ant-table-filter-trigger {
      display: flex;
      align-items: center;
      margin-inline: 0;
      margin-left: 2px;

      &:hover {
        background: transparent;
      }

      &.active {
        color: ${({ theme }) => theme.table.sorterArrow.activeArrow || colors.primary}
      }
    }

    .ant-table-column-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      flex: initial !important;
    }

    .custom-table-sorters {
      margin-left: 6px;
    }
  }

  .ant-table-tbody {
    tr.ant-table-placeholder {
      td {
        border-radius: ${borderRadius} !important;
      }
    }
  }

  tbody tr:not(.ant-table-measure-row) td {
    padding: 14px 10px !important;
    font-size: ${(props) => props.theme.table.tableElement.size} !important;
    font-weight: ${(props) => props.theme.table.tableElement.weight} !important;
    font-family: ${(props) => props.theme.table.tableElement.family} !important;
    color: ${(props) => props.theme.table.tableElement.color} !important;

    &.ant-table-cell:first-child {
      font-weight: 600 !important;
    }
  }

  .ant-pagination {
    margin: 20px 0 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    float: none !important;
    background: transparent !important;

    li {
      margin: 0 5px !important;
    }
  }

  .ant-pagination-item {
    margin: 0 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.passiveItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.passiveItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.passiveItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.passiveItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.passiveItem.color} !important;
      line-height: 32px !important;
    }
  }
  .ant-pagination-item-active {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.activeItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.activeItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.activeItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.activeItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.activeItem.color} !important;
      line-height: 32px !important;
    }
  }
  .ant-pagination-item-link {
    font-size: ${({ theme }) => theme.table.pagination.arrow.size} !important;
    font-weight: ${({ theme }) =>
      theme.table.pagination.arrow.weight} !important;
    font-family: ${({ theme }) =>
      theme.table.pagination.arrow.family} !important;
    color: ${({ theme }) => theme.table.pagination.arrow.passive} !important;

    &:hover {
      color: ${({ theme }) => theme.table.pagination.arrow.active} !important;
    }
  }
`;

const LikeLink = styled.span`
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin: 0 8px 0 0;
  color: ${({ theme }) => theme.table.tableLink.passive} !important;
  font-size: ${({ theme }) => theme.table.tableLink.size} !important;
  font-weight: ${({ theme }) => theme.table.tableLink.weight} !important;
  font-family: ${({ theme }) => theme.table.tableLink.family} !important;

  &:hover {
    color: ${({ theme }) => theme.table.tableLink.active} !important;
  }

  a {
    color: ${({ theme }) => theme.table.tableLink.passive} !important;
    font-size: ${({ theme }) => theme.table.tableLink.size} !important;
    font-weight: ${({ theme }) => theme.table.tableLink.weight} !important;
    font-family: ${({ theme }) => theme.table.tableLink.family} !important;

    &:hover {
      color: ${({ theme }) => theme.table.tableLink.active} !important;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 30px;
  height: 40px;

  button {
    height: 40px;
    padding: 0 28px;
    font-size: ${({ theme }) => theme.buttonToAdd.size} !important;
    font-weight: ${({ theme }) => theme.buttonToAdd.weight} !important;
    font-family: ${({ theme }) => theme.buttonToAdd.family} !important;
    color: ${({ theme }) => theme.buttonToAdd.passive} !important;
    background: ${({ theme }) =>
      theme.buttonToAdd.backgroundPassive || colors.primary};
    outline: none !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.buttonToAdd.borderRadius || borderRadius} !important;
    box-shadow: ${({ theme }) =>
      theme.buttonToAdd.boxShadow || 'rgba(0, 0, 0, 0.25) 0 4px 4px 0'};

    &:focus {
      outline: none !important;
      border: none !important;
    }

    &:hover {
      color: ${({ theme }) => theme.buttonToAdd.active} !important;
      background: ${({ theme }) =>
        theme.buttonToAdd.backgroundActive || colors.secondary} !important;
      outline: none !important;
      border: none !important;
      margin-top: ${({ theme }) => theme.buttonToAdd.marginTopActive};
    }
  }
`;

export { StyledTable, LikeLink, Header };
