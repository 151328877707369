import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import components from './components';
import CommonPlot from '../../components/CommonPlot';
import Recency from 'sections/Cockpit/components/Recency';
import TraceabilityQuality from 'sections/Cockpit/components/TraceabilityQuality';
import ErrorPage from './error-page';
import { fetchData } from './fetchData';
import errorHandler from '../../lib/errorHandler';
import FontStyles from './font-family';
import { PageSchema } from '../GenericTablePage/types';
import { StyledWrapper } from './styled-components';
import LanguageSelector from './components/LanguageSelector';

interface Props {
  businessName: string;
  businessId: number;
  pageSchema: PageSchema;
  country: string;
}
interface DashboardData {
  schema: DashboardSchema;
  title?: string;
  subtitle?: string;
  image?: string;
}
interface DashboardComponent {
  component?: string;
  styles: object;
  [key: string]: any;
}
interface DashboardSchema {
  [key: string]: DashboardComponent;
}

export const blocks = {
  ...components,
  CommonPlot,
  Recency,
  TraceabilityQuality,
};

const CommonDashboard = ({ businessName, businessId, pageSchema }: Props) => {
  const [data, setData] = useState<DashboardData>({ schema: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const params = useParams();
  const [defaultLanguage, setDefaultLanguage] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [languageList, setLanguageList] = useState([]);

  const initDashboardData = () => {
    fetchData({ businessName, params, filters, pageSchema, language })
      .then((response: any) => {
        if (response?.schema) {
          setData(response);

          if (!language) {
            setDefaultLanguage(response?.defaultLang);
            setLanguageList(response?.languageList);
          }
        } else {
          setErrorMessage(response?.error);
        }
        setIsLoading(false);
      })
      .catch((err: Error[]) => {
        errorHandler(err, 'CommonDashboard');
        setErrorMessage(err[0]?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    initDashboardData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName, pageSchema?.path, filters, language]);

  const refreshData = () => {
    setIsLoading(true);
    initDashboardData();
  };

  const content =
    data && data?.schema && Object.keys(data?.schema).length ? (
      Object.keys(data?.schema)?.map((componentName: string, index: number) => {
        if (data?.schema[componentName]?.subItem) {
          return null;
        }

        const name =
          data?.schema[componentName]?.component === 'Table' ||
          componentName === 'Table'
            ? 'FrontendPaginatedTable'
            : data?.schema[componentName]?.component || componentName;

        const Component = blocks[name];
        const props = {
          ...data?.schema[componentName],
          businessName,
          businessId,
          filters,
          setFilters,
          schema: data?.schema,
          pageSchema,
          urlParams: params,
          componentName,
          language: language || defaultLanguage,
          refreshData,
        };

        return <Component key={`dashboard-component-${index}`} {...props} />;
      })
    ) : (
      <ErrorPage message={errorMessage} />
    );

  return (
    <StyledWrapper>
      <FontStyles />
      {!isLoading ? (
        <>
          <div className={'dashboard-header'}>
            {data?.image && (
              <img
                src={data?.image}
                className={'circle-header-logo'}
                alt="Project Logo"
              />
            )}
            <div
              className={`dashboard-header-text ${
                !data?.image ? 'text-center' : ''
              }`}
            >
              {data?.title && (
                <p className={'dashboard-title'}> {data?.title} </p>
              )}
              {data?.subtitle && (
                <p className={'dashboard-subtitle'}> {data?.subtitle} </p>
              )}
            </div>
          </div>
          <div className={'wrapper'}>
            {defaultLanguage && (
              <LanguageSelector
                language={language || defaultLanguage}
                languageList={languageList}
                handleLanguageChange={setLanguage}
              />
            )}
            {content}
          </div>
        </>
      ) : (
        <Spin style={{ display: 'block' }} size="large" />
      )}
    </StyledWrapper>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: {
        business: { name, id },
      },
    },
  },
}: {
  auth: {
    currentUser: { attributes: { business: { name: string; id: number } } };
  };
}) => ({
  businessName: name,
  businessId: id,
});

export default connect(mapStateToProps)(CommonDashboard);
