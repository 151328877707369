import { FC, useEffect, useState } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { Empty, Row, Space, Spin } from 'antd';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { csv2json } from 'json-2-csv';
import { useTranslation } from 'react-i18next';
import apiClient from 'lib/apiClient';
import DownloadLink from './components/DownloadLink';
import ContentErrorBoundary from './components/ContentErrorBoundary';
import FrontEndTableComponent from 'sections/GenericTablePage/table/front-end-paginated-table';
import { Header, SResult, Title, Wrapper } from './styled-components';

const COLUMN_WIDTH = 190;
const DELIMITERS = [',', ';', '\t', '|'];

interface FileDetailsProps {
  isShowFile: boolean;
  data: {
    id: string | number;
    csv_file_name: string;
    csv_url: string;
    log_file_name: string;
    log_url: string;
  };
  date: string;
}

const FileDetails: FC<FileDetailsProps> = ({
  data: { id, csv_file_name, csv_url, log_file_name, log_url },
  isShowFile,
  date,
}) => {
  const [content, setContent] = useState<{
    data: { [key: string]: any }[];
    schema: { fieldName: string; label: string }[];
  }>();
  const [tableWidth, setTableWidth] = useState<number>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const { data }: QueryObserverResult<{ data: string }, any> = useQuery(
    ['fileDetails', id],
    async () => {
      const res = await await apiClient.getUploadFile({
        id,
        type: isShowFile ? 'csv' : 'log',
      });

      return res;
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        console.log('Error when getting data:', error);
        setError(true);
        setIsLoading(false);
      },
    }
  );

  const detectDelimiter = (csvString) => {
    const firstLine = csvString.split('\n')[0];

    const delimiterCount = DELIMITERS.map((delimiter) => {
      return {
        delimiter,
        count: firstLine.split(delimiter).length - 1,
      };
    });

    // Sort by count in descending order and return the delimiter with the most splits
    delimiterCount.sort((a, b) => b.count - a.count);

    return delimiterCount[0].delimiter;
  };

  useEffect(() => {
    if (data?.data !== null && data?.data !== undefined) {
      if (data.data.trim() === '') {
        setContent({ data: [], schema: [] });
        setIsLoading(false);
        return;
      }

      const delimiter = detectDelimiter(data.data);

      try {
        const jsonData = csv2json(data.data, {
          delimiter: { field: delimiter || ';' },
          parseValue: (value) => {
            return value.toString();
          }
        });

        const flattenedData = jsonData?.map((item: { [key: string]: any }) => {
          const flattenedItem = new Map<string, any>();

          for (const key in item) {
            if (key === 'C') {
              for (const compositionKey in item.C) {
                flattenedItem.set(
                  `C.${compositionKey}`,
                  item.C[compositionKey]
                );
              }
            } else {
              flattenedItem.set(key, item[key]);
            }
          }

          const orderedObject: { [key: string]: any } = {};
          flattenedItem.forEach((value, key) => {
            orderedObject[key] = value;
          });

          return orderedObject;
        });

        const schema = Object.keys(flattenedData[0]).map((key) => {
          return {
            fieldName: key,
            label: key,
          };
        });

        const columnsQty = schema.length * COLUMN_WIDTH;

        setTableWidth(columnsQty);
        setContent({ data: flattenedData, schema });
        setIsLoading(false);

      } catch (e) {
        console.log('Error while converting to json: ', e.message);
        setError(true);
        setIsLoading(false);
      }
    }
  }, [data, id]);

  let file: { name?: string; url?: string } = {};

  if (isShowFile) {
    file.name = csv_file_name;
    file.url = csv_url;
  } else {
    file.name = log_file_name;
    file.url = log_url;
  }

  const fallbackContent = data?.data ? <pre>{data?.data}</pre> : null;

  return (
    <Wrapper>
      <Header justify="space-between">
        <div className="file-information">
          <Space direction="vertical" size="small">
            <Row>
              <FileOutlined />
              <Title id={'upload-file-name'}>{file.name}</Title>
            </Row>
            <Row>
              <CalendarOutlined /> <Title>{date}</Title>
            </Row>
          </Space>
        </div>
        {data?.data !== null && data?.data !== undefined && (
          <DownloadLink file={file} />
        )}
      </Header>
      <ContentErrorBoundary fallbackContent={fallbackContent}>
        {isLoading ? (
          <Spin style={{ display: 'block' }} size="large" />
        ) : error ? (
          <SResult
            icon={<CloseCircleOutlined />}
            title={t('errors.somethingWentWrong')}
            subTitle={t('errors.somethingWentWrong')}
          />
        ) : !content?.data.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <FrontEndTableComponent
            dataSource={content?.data}
            columns={content?.schema}
            pagination={{
              showSizeChanger: false,
              defaultPageSize: 50,
            }}
            getRowKey={(record) => record.traceId}
            isSearchEnabled={false}
            scroll={{ x: tableWidth, y: 390 }}
            tableSize={'small'}
          />
        )}
      </ContentErrorBoundary>
    </Wrapper>
  );
};

export default FileDetails;
