import { FC, useState } from 'react';
import { Button, Space } from 'antd';
import {
  PlusCircleOutlined,
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ExportTemplatesButton from 'containers/ExportTemplatesButton';
import { HeaderAction, PageSchema, TableFilters } from 'sections/GenericTablePage/types';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import exportAllItems from 'sections/GenericTablePage/helpers/exportAllItems';
import { EXPORT_ALL_ITEMS_ACTION, EXPORT_TEMPLATES_ACTION } from 'sections/GenericTablePage/actions';

const prefix_id = 'generic-header-actions';

const idGenerator = new UniqIdsGenerator(prefix_id);

type Props = {
  actions: Array<HeaderAction>;
  handleClick: Function;
  isAllowed: boolean;
  total?: number;
  list_name?: string;
  searchField?: string;
  filter?: TableFilters;
  exportAllButtonOptions?: {
    customExportPath?: string | string[];
    customHeaderExportKeys?: string[];
    extraColumns?: any;
    exportDashboardTableData?: boolean;
    customExportFilename?: string;
  };
  pageSchema?: PageSchema;
};

export const icons = {
  'plus-circle': PlusCircleOutlined,
  'check': CheckOutlined,
  'download': DownloadOutlined,
};

const HeaderActions: FC<Props> = ({
  actions,
  handleClick,
  isAllowed,
  total,
  list_name,
  searchField,
  filter,
  exportAllButtonOptions,
  pageSchema,
}) => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const businessName = useSelector(({ auth }: any) => auth.currentUser.attributes?.business?.name);
  const { t } = useTranslation();

  const onExport = () => {
    setIsExporting(true);

    exportAllItems({
      list_name,
      total,
      searchField,
      filter,
      ...exportAllButtonOptions,
      pageSchema,
      businessName,
      onExportComplete: setIsExporting,
      t,
    });
  };

  return (
    <Space>
      {Array.isArray(actions) &&
        actions.map(
          ({ label, action, icon, display, uploadType }, fieldName) => {
            const IconComponent = icons[icon];
            const isExportItemsBtn = action.includes(EXPORT_ALL_ITEMS_ACTION);
            const isExportTemplatesBtn = action.includes(
              EXPORT_TEMPLATES_ACTION
            );
            const isAddBtn = !isExportItemsBtn && !isExportTemplatesBtn;
            let displayButton;

            switch (true) {
              case isAddBtn:
                displayButton = display !== undefined ? display : true;

                return displayButton ? (
                  <Button
                    key={`${label}${fieldName}`}
                    onClick={() => handleClick(action)}
                    id={idGenerator.getTrimmedString(label)}
                    disabled={!isAllowed}
                    className={'header-add-button'}
                  >
                    {icon && IconComponent && <IconComponent />}
                    {label}
                  </Button>
                ) : null;
              case isExportItemsBtn:
                displayButton = display || false;

                return display ? (
                  <Button
                    key={`${label}${fieldName}`}
                    onClick={() => onExport()}
                    id={idGenerator.getTrimmedString(label)}
                    className={`header-button`}
                    disabled={isExporting}
                  >
                    {isExporting ? (
                      <LoadingOutlined />
                    ) : (
                      <Space size={4}>
                        {icon && IconComponent && <IconComponent />}
                        {label}
                      </Space>
                    )}
                  </Button>
                ) : null;
              case isExportTemplatesBtn:
                return display ? (
                  <ExportTemplatesButton
                    key={`${label}${fieldName}`}
                    label={label}
                    icon={IconComponent}
                    uploadType={uploadType}
                  />
                ) : null;
              default:
                return null;
            }
          }
        )}
    </Space>
  );
};

export default HeaderActions;
