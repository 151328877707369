import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
  createTransform,
} from 'redux-persist';
import { createBrowserHistory } from 'history';

import reducer from '../reducers';
import { MigrationManifest } from 'redux-persist/es/types';
import {
  conformityFormModal,
  formWorkerModal,
  supplierStatusFormModal,
} from 'sections/GenericTablePage/constants';

const migrations: MigrationManifest = {
  // @ts-ignore
  2: (state) => {
    // migration to keep only auth state
    return {
      // @ts-ignore
      auth: state?.auth,
    };
  },
};

const SetTransform = createTransform(
  (inboundState: object, key) => {
    if (key === 'auth') {
      const nextState = _.cloneDeep(inboundState);
      _.hasIn(
        nextState,
        'currentUser.attributes.business.notifications_polling_period'
      ) &&
        _.set(
          nextState,
          'currentUser.attributes.business.notifications_polling_period',
          null
        );
      return nextState;
    }
  },
  (outboundState, key) => {
    // @ts-ignore
    return { ...outboundState };
  },
  { whitelist: ['auth'] }
);

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [SetTransform],
  blacklist: ['networkEvents', 'genericModal', 'router'],
};

const RESET_ALL_DATA = 'ROOT::RESET_ALL_DATA';

export const resetAllDataAction =
  () => (dispatch: (action: { type: string }) => any) =>
    dispatch({ type: RESET_ALL_DATA });

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const combinedReducers = combineReducers({
  router: routerReducer,
  ...reducer,
});

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === RESET_ALL_DATA) {
    localStorage.clear();
    sessionStorage.clear();

    // Excluding router reducer from resetting
    const { router } = state;
    state = { router };
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these paths in the state
        ignoredPaths: [
          `genericModal.${formWorkerModal}.data.handleSubmit`,
          `genericModal.${formWorkerModal}.data.handleSubTitle`,
          `genericModal.${supplierStatusFormModal}.data.refreshData`,
          `genericModal.${conformityFormModal}.data.handleSubmit`,
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          'payload.data.handleSubmit',
          'payload.data.handleSubTitle',
          'payload.data.refreshData',
        ],
      },
    }).concat(routerMiddleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
  // Optional Redux store enhancers
  // enhancers: (getDefaultEnhancers) => {},
});

export const history = createReduxHistory(store);
