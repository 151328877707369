import styled from 'styled-components';
import { font } from 'App/styleVariables';

interface Block {
  styles: any;
}

export const SContainer = styled.div<Block>`
  grid-column-start: ${({ styles }) => styles?.size?.column?.start};
  grid-column-end: ${({ styles }) => styles?.size?.column?.end};
  grid-row-start: ${({ styles }) => styles?.size?.row?.start};
  grid-row-end: ${({ styles }) => styles?.size?.row?.end};
  box-sizing: border-box;
  background: ${({ styles, theme }) =>
    styles?.background || theme?.dashboard?.block?.background || '#ffffff'};
  box-shadow: ${({ styles }) =>
    styles?.boxShadow || '0 0 5px 5px rgba(0, 0, 0, 0.03)'};
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ styles, theme }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;

  .categories-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .category-item {
    display: flex;

    .badge-content {
      font-weight: ${({ styles }) => styles?.category?.fontWeight || 600};
    }
  }
`;
