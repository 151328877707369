import { FC, useEffect, useState } from 'react';
import { Flex, Segmented, Typography } from 'antd';
import ProgressBar from 'components/ProgressBar';
import { SCardWrapper, SLegendItem } from './styled-components';

interface BarDataProps {
  key: string;
  label: string;
  value: string | number;
  tooltip: string;
}

interface StatisticCardWithProgressBarProps {
  title: string;
  totalQty: string | number;
  segmented?: boolean;
  progressData: {
    option: string;
    barData: BarDataProps[];
  }[];
  trailColor?: string;
  completedColor?: string;
  progressColor?: string;
  strokeHeight?: string;
  borderRadius?: string;
  displayLegend?: boolean;
  legendLabelColor?: string;
  showBarTooltip?: boolean;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StatisticCardWithProgressBar: FC<StatisticCardWithProgressBarProps> = ({
  title,
  totalQty,
  segmented,
  progressData,
  trailColor,
  completedColor,
  progressColor,
  strokeHeight,
  borderRadius,
  displayLegend,
  legendLabelColor,
  showBarTooltip,
  styles,
}) => {
  const [value, setValue] = useState<string | number>();
  const [barData, setBarData] = useState(null);

  useEffect(() => {
    const defaultData = progressData[0];
    setValue(defaultData?.option);
  }, [progressData]);

  useEffect(() => {
    const result = progressData.filter((item) => item.option === value)[0];
    setBarData(result?.barData);
  }, [value, progressData]);

  const getDataByType = (
    data: BarDataProps[],
    type: 'completed' | 'progress' | 'balance'
  ) => {
    const filteredData = data?.filter((item) => item.key === type)[0];

    return {
      value: filteredData?.value,
      tooltip: filteredData?.tooltip,
    };
  };

  const getColorByBarType = (type: 'completed' | 'progress' | 'balance') => {
    switch (type) {
      case 'completed':
        return completedColor;
      case 'progress':
        return progressColor;
      default:
        return trailColor;
    }
  };

  return (
    <SCardWrapper styles={styles}>
      <Flex gap="middle">
        <div className="title-block">
          <Typography.Title level={3}>{title}</Typography.Title>
          <p className="total">{totalQty}</p>
        </div>
        <div className="progress-block">
          {segmented && (
            <Segmented
              options={progressData.map((item) => item.option)}
              value={value}
              onChange={setValue}
              className="segmented-filters"
            />
          )}
          <ProgressBar
            completed={getDataByType(barData, 'completed').value}
            progress={getDataByType(barData, 'progress').value}
            total={totalQty}
            trailColor={trailColor}
            completedColor={completedColor}
            progressColor={progressColor}
            strokeHeight={strokeHeight}
            borderRadius={borderRadius}
            showBalance={true}
            showTooltip={showBarTooltip}
            completedTooltip={getDataByType(barData, 'completed').tooltip}
            progressTooltip={getDataByType(barData, 'progress').tooltip}
            balanceTooltip={getDataByType(barData, 'balance').tooltip}
          />
          {displayLegend && (
            <Flex
              gap="middle"
              align="center"
              justify="end"
              className={'legend'}
            >
              {barData?.map(({ key: type, label }, index) => (
                <SLegendItem
                  $color={getColorByBarType(type)}
                  $labelColor={legendLabelColor}
                  key={`legent-item-${index}`}
                >
                  <span>{label}</span>
                </SLegendItem>
              ))}
            </Flex>
          )}
        </div>
      </Flex>
    </SCardWrapper>
  );
};

export default StatisticCardWithProgressBar;
