import { FC } from 'react';
import { Button, List, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { store } from 'store/nextStore';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from 'containers/GenericModal/reducer';
import { docReaderModal, galleryModal } from 'components/Modal/constants';
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { SListItem } from '../FormSteps/formFilling/style';

interface FileSelectWithReaderProps {
  value: string;
  setFieldValue: (field: string, value: any) => Promise<void>;
  setFieldTouched: (field: string, isTouched?: boolean) => Promise<void>;
  name: string;
  values: { [key: string]: string };
  formType: string;
  formSchema: { [key: string]: any };
}

const FileSelectWithReader: FC<FileSelectWithReaderProps> = ({
  value: fileName,
  setFieldValue,
  setFieldTouched,
  name,
  values,
  formType: traceType,
  formSchema,
}) => {
  const { t } = useTranslation();

  const onSelect = (file) => {
    setFieldValue(name, file.file_name);
    setFieldValue(name.replace('Filename', 'Url'), file.file_url);
    setFieldValue(name.replace('Filename', 'Hash'), file.file_hash);

    store.dispatch(closeGenericModalAction({ name: galleryModal }));
    viewDocReader(file.file_url);
  };

  const onClick = () => {
    return store.dispatch(
      openGenericModalAction({
        name: galleryModal,
        configuration: {
          title: t('form.selectDocument'),
          width: '70vw',
          prefixId: 'gallery',
        },
        data: {
          item: fileName,
          modalName: galleryModal,
          onSelect: onSelect,
          headerActionsSchema: [
            {
              label: t('form.addItem'),
              action: 'addItem',
              icon: 'plus-circle',
            },
          ],
        },
      })
    );
  };

  const viewDocReader = (url?: string) => {
    store.dispatch(
      openGenericModalAction({
        name: docReaderModal,
        configuration: {
          title: t('form.validateDocument'),
          width: '80vw',
          prefixId: 'doc_reader',
        },
        data: {
          initialFileUrl: url || values[name.replace('Filename', 'Url')],
          setFieldValue,
          setFieldTouched,
          values,
          name,
          traceType,
          formSchema,
        },
      })
    );
  };

  const removeFile = () => {
    setFieldValue(name, '');
    setFieldValue(name.replace('Filename', 'Url'), '');
    setFieldValue(name.replace('Filename', 'Hash'), '');
  };

  return (
    <>
      <Button type="dashed" onClick={onClick} className="document-select-btn">
        {fileName ? (
          <>
            <EditOutlined /> {t('form.changeDocument')}
          </>
        ) : (
          <>
            <PlusOutlined /> {t('form.chooseDocument')}
          </>
        )}
      </Button>
      {fileName && (
        <List
          className="document-list"
          itemLayout="horizontal"
          dataSource={[
            {
              name: fileName,
              url: values[name.replace('Filename', 'Url')],
            },
          ]}
          split={false}
          renderItem={(item: any, index: number) => {
            const actions = [
              <Tooltip
                title={t('form.openDocumentReader')}
                overlayClassName="white-tooltip"
              >
                <Button
                  key="list-file-reader"
                  onClick={() => viewDocReader()}
                  className="list-action-btn"
                >
                  <FileTextOutlined />
                </Button>
              </Tooltip>,
              <Tooltip
                title={t('form.removeCurrentDocument')}
                overlayClassName="white-tooltip"
              >
                <Button
                  key="list-rile-remove"
                  onClick={() => removeFile()}
                  className="list-action-btn"
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>,
            ];

            return (
              <SListItem actions={actions}>
                <List.Item.Meta
                  avatar={<FileOutlined />}
                  title={
                    <a href={item?.url} className="list-item-title-link">
                      {item.name}
                    </a>
                  }
                />
              </SListItem>
            );
          }}
        />
      )}
    </>
  );
};

export default FileSelectWithReader;
