import React from 'react';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import {
  GetRowKey,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import { Input, SpinProps } from 'antd';
import { TableSchema, TableData, TableFilters, PageSchema } from '../types';
import ColumnPropsBuilder from '../helpers/builders/column-props-builder';
import HeaderActions from '../components/HeaderActions';
import addChildren from '../helpers/addChildren';
import {
  Header,
  SWrapperTable,
  SearchWrapper,
  StyledPagination,
  StyledTable,
} from '../styled-components';

const { Search } = Input;

type Props = {
  columns: TableSchema[];
  dataSource: TableData[];
  pagination: false | TablePaginationConfig | undefined;
  handleTableChange:
    | ((
        pagination: TablePaginationConfig,
        filters: Record<never, string[]>,
        sorter: SorterResult<unknown>,
        extra: TableCurrentDataSource<unknown>
      ) => void)
    | undefined;
  isLoading: boolean | SpinProps;
  onRowAction: Function;
  actions: [];
  actionHandler: Function;
  isAllowed: boolean;
  getRowKey: string | GetRowKey<object>;
  isSearchEnabled: boolean;
  setSearchField: Function;
  searchButtonText?: boolean;
  searchPlaceholder?: string;
  setPage: Function;
  scroll: object;
  sortedInfo?: any;
  filteredInfo?: any;
  isFrontTableControlled?: boolean;
  apiPaginatedTable: boolean;
  total?: number;
  page?: number;
  pageName: string;
  list_name?: string;
  historyObjectId?: number | string;
  tableSize?: 'small' | 'middle' | 'large';
  searchField?: string;
  filter?: TableFilters;
  t: (string) => string;
  exportAllButtonOptions?: {
    customExportPath?: string | string[];
    customHeaderExportKeys?: string[];
    extraColumns?: any;
    exportDashboardTableData?: boolean;
    customExportFilename?: string;
  };
  pageSchema: PageSchema;
};

type State = {
  data: TableData[];
  searchValue?: String;
  historyObjectId?: number | string;
};

class GenericTable extends React.Component<Props, State> {
  onRowAction = this.props.onRowAction;

  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<never, string[]>,
    sorter: SorterResult<unknown>,
    extra: TableCurrentDataSource<unknown>
  ) => void | undefined;
  onSearch: ((value: string, event?: any) => any) | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.dataSource,
      historyObjectId: props.historyObjectId,
    };

    // Assign handleTableChange directly from props or fallback
    this.handleTableChange =
      this.props.handleTableChange || this.handleTableChange;
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { dataSource } = this.props;
    if (prevProps.dataSource !== dataSource) {
      this.setState({
        data: dataSource,
      });
    }
  }

  addFieldsToColumn(
    columnSchema: TableSchema,
    columnProps: ColumnProps<any>
  ): ColumnProps<any> {
    const builder = new ColumnPropsBuilder(columnProps);

    return builder.build();
  }

  buildColumn(columnSchema: TableSchema): ColumnProps<any> {
    const { sortedInfo, filteredInfo } = this.props;
    const { historyObjectId } = this.state;
    const builder = new ColumnPropsBuilder();

    builder
      .addTitle(
        columnSchema.label,
        columnSchema.helpType,
        columnSchema.helpContent
      )
      .addDataIndex(columnSchema.fieldName)
      .addKey(columnSchema.fieldName)
      .addWidth(columnSchema)
      .addRender((text, record) => {
        return {
          props: {
            className: 'classNameOfCell',
          },
          children: addChildren(
            columnSchema,
            record,
            this.onRowAction,
            historyObjectId
          ),
        };
      })
      .addChild(columnSchema.children, this.buildColumn.bind(this))
      .addFilterDropdown(columnSchema.customFilter, columnSchema.fieldName)
      .addSortDirections()
      .addDefaultSortDirections(columnSchema.defaultSortOrder)
      .addSortOrder(sortedInfo)
      .addFilters(columnSchema.filters)
      .addFilteredValue(filteredInfo)
      .addCustomSorterArrows()
      .addEllipsis();

    return this.addFieldsToColumn(columnSchema, builder.build());
  }

  render() {
    let {
      columns,
      pagination,
      isLoading,
      actions,
      actionHandler,
      getRowKey,
      isAllowed,
      isSearchEnabled,
      searchButtonText = true,
      searchPlaceholder = '',
      scroll,
      isFrontTableControlled,
      apiPaginatedTable,
      total,
      page,
      setPage,
      list_name,
      tableSize = 'middle',
      searchField,
      filter,
      t,
      exportAllButtonOptions,
      pageSchema,
    } = this.props;
    const { data } = this.state;

    return (
      <>
        <Header
          className={`table-header ${
            !actions && !isSearchEnabled ? 'hidden' : ''
          }`}
        >
          {isSearchEnabled && (
            <SearchWrapper>
              <Search
                id={'search-input'}
                placeholder={searchPlaceholder}
                enterButton={!!searchButtonText && t('common.search')}
                style={{ width: 363 }}
                onSearch={this.onSearch}
              />
            </SearchWrapper>
          )}
          <HeaderActions
            actions={actions}
            handleClick={actionHandler}
            isAllowed={isAllowed}
            total={total}
            list_name={list_name}
            exportAllButtonOptions={exportAllButtonOptions}
            searchField={searchField}
            filter={filter}
            pageSchema={pageSchema}
          />
        </Header>
        <SWrapperTable className="table-wrapper">
          <StyledTable
            className={'table-container'}
            dataSource={data}
            rowKey={getRowKey}
            columns={columns.map((column) => this.buildColumn(column))}
            pagination={pagination}
            onChange={this.handleTableChange}
            loading={isLoading}
            size={tableSize}
            showSorterTooltip={false}
            scroll={scroll}
            rowClassName={(record, index) =>
              index === data.length - 1 ? 'table-last-row' : ''
            }
            isFrontTableControlled={isFrontTableControlled}
          />
          {apiPaginatedTable && (
            <StyledPagination
              total={total}
              size="small"
              pageSize={10}
              current={page}
              onChange={(page) => setPage(+page)}
              showSizeChanger={false}
            />
          )}
        </SWrapperTable>
      </>
    );
  }
}

export default GenericTable;
