import { FC } from 'react';
import GenericTablePage from 'sections/GenericTablePage';
import { DASHBOARD_BACKEND_TABLE } from 'sections/GenericTablePage/constants';
import StyledTableWrapper from './styled-component';
import DownloadMonitoringReport from 'components/DownloadMonitoringReport';

interface TableProps {
  table_name: string;
  scroll?: object;
  isSearchEnabled?: boolean;
  searchButtonText?: boolean;
  searchPlaceholder?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  indicators?: string[];
  download?: boolean;
  downloadButtonPosition?: 'top' | 'bottom' | undefined;
  bordered?: boolean;
  exportFilename?: string;
  downloadMonitoringReport?: boolean;
  downloadMonitoringReportButtonText?: string;
  downloadMonitoringReportPattern?: string;
  businessName?: string;
  options: {
    expandArrayObjects: boolean;
    unwindArrays: boolean;
    excludeKeys: string[];
  };
  footerDescription?: string;
  backendPaginated?: boolean;
  pageSchema: any;
  displayExportItemsButton?: boolean;
  exportItemsButtonLabel?: string;
  customExportPath?: string | string[];
  customHeaderExportKeys?: string[];
  extraColumns?: any;
  exportDashboardTableData?: boolean;
  filters?: any;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const BackendPaginatedTable: FC<TableProps> = ({
  styles,
  isSearchEnabled = false,
  searchButtonText = true,
  searchPlaceholder,
  title,
  subtitle,
  image,
  indicators,
  bordered = false,
  exportFilename,
  downloadButtonPosition = 'top',
  downloadMonitoringReport = false,
  downloadMonitoringReportButtonText,
  downloadMonitoringReportPattern,
  businessName,
  footerDescription,
  pageSchema,
  table_name,
  displayExportItemsButton,
  exportItemsButtonLabel,
  exportDashboardTableData,
  customExportPath,
  customHeaderExportKeys,
  extraColumns,
  filters,
}) => {
  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${image}`;

  const normalizeFiltersObject = (obj) => {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value === null || value === undefined) {
          result[key] = null;
        } else {
          result[key] = Array.isArray(value) ? value : [value];
        }
      }
    }

    return result;
  };

  return (
    <StyledTableWrapper styles={styles} $bordered={bordered}>
      {(title ||
        (downloadMonitoringReport && downloadButtonPosition === 'top')) && (
        <div className={'header'}>
          {title && (
            <div>
              <div className={'title-wrapper'}>
                {image && (
                  <img
                    src={imageSrc}
                    className={'title-icon'}
                    alt="Table icon"
                  />
                )}
                <p className={'title'}>{title}</p>
              </div>
              {subtitle && <p className={'subtitle'}>{subtitle}</p>}
            </div>
          )}
          {indicators && (
            <div className={'indicators-wrapper'}>
              {indicators.map((item, index) => (
                <p key={`indicator-${index}`} className={'indicator'}>
                  {item}
                </p>
              ))}
            </div>
          )}
          {downloadMonitoringReport && (
            <DownloadMonitoringReport
              label={downloadMonitoringReportButtonText}
              icon="download"
              options={{
                pattern: downloadMonitoringReportPattern,
              }}
              className="download-btn"
            />
          )}
        </div>
      )}
      <GenericTablePage
        pageName={`${DASHBOARD_BACKEND_TABLE}_${table_name}`}
        withWrapper={false}
        pageSchema={{
          component: DASHBOARD_BACKEND_TABLE, // need for Table Header Actions
          path: pageSchema.path,
          options: {
            table_name,
            display_export_items_button: displayExportItemsButton,
            export_items_button_label: exportItemsButtonLabel,
          },
        }}
        isSearchBarEnabled={isSearchEnabled}
        preFilters={normalizeFiltersObject(filters)}
        searchPlaceholder={searchPlaceholder}
        searchButtonText={searchButtonText}
        exportAllButtonOptions={{
          customExportPath,
          customHeaderExportKeys,
          extraColumns,
          exportDashboardTableData,
          customExportFilename: exportFilename,
        }}
      />
      <div className={'footer'}>
        {footerDescription && (
          <p className="footer-description">{footerDescription}</p>
        )}
        {downloadMonitoringReport && downloadButtonPosition === 'bottom' && (
          <DownloadMonitoringReport
            label={downloadMonitoringReportButtonText}
            icon="download"
            options={{
              pattern: downloadMonitoringReportPattern,
            }}
            className="download-btn"
          />
        )}
      </div>
    </StyledTableWrapper>
  );
};

export default BackendPaginatedTable;
