import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import styled from 'styled-components';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';

import styles from './styles';

const StyledModal = styled(Modal)`
  .ant-modal-title {
    font-family: ${(props) => props.theme.form.title?.family};
    color: ${(props) => props.theme.form.title?.color};
    font-size: ${(props) => props.theme.form.title?.size};
  }
  
  ${(props) => props.prefixId && styles[props.prefixId]}
`;

const CustomModal = ({
  closeModal,
  afterClose,
  title,
  modalVisible = false,
  children,
  prefixId,
  width,
}) => {
  const uniqId = new UniqIdsGenerator(prefixId);
  localStorage.removeItem('val'); // ugly solution. it should be removed
  return (
    <StyledModal
      title={title}
      open={modalVisible}
      onCancel={closeModal}
      afterClose={afterClose}
      prefixId={prefixId}
      footer={null}
      width={width}
      maskClosable={false}
    >
      <div className="content-wrapper" id={uniqId.getModalContentDivWrapper()}>
        {children}
      </div>
    </StyledModal>
  );
};

CustomModal.propTypes = {
  /**
   * Modal title
   */
  title: PropTypes.string,
  /**
   * Function that will be called when a user clicks close button or Cancel button
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Whether the Modal window is visible or not
   */
  modalVisible: PropTypes.bool,
  /**
   * Content that will display in the Modal
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  /**
   * Function that will be called when modal is closed completely
   */
  afterClose: PropTypes.func,
  /**
   * Width of the modal window
   */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * id for Modal window
   */
  prefixId: PropTypes.string,
};

export default CustomModal;
