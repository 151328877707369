import { message } from 'antd';
import client from 'lib/apiClient';
import { downloadFile } from 'lib/util';
import { getDashboardName } from 'sections/CommonDashboard/util';
import { PageSchema } from '../types';

const ITEMS_PER_PAGE: number = 1000;

interface ExportAllItemsArgs {
  list_name: string;
  total: number;
  customExportPath?: string | string[];
  searchField?: string;
  filter?: object;
  customHeaderExportKeys?: string[];
  extraColumns?: any;
  exportDashboardTableData?: boolean;
  customExportFilename?: string;
  pageSchema?: PageSchema;
  businessName: string;
  onExportComplete?: (param: boolean) => void;
  t: (string) => string;
}

const exportAllItems = async ({
  list_name,
  customExportPath,
  searchField,
  filter,
  total,
  customHeaderExportKeys,
  extraColumns,
  exportDashboardTableData,
  customExportFilename,
  pageSchema,
  businessName,
  onExportComplete,
  t,
}: ExportAllItemsArgs) => {
  const worker = new Worker(new URL('./../export.worker.ts', import.meta.url));

  let data = [],
    object_type: string;

  if (exportDashboardTableData) {
    const dashboard_name: string = getDashboardName(businessName, pageSchema);
    const table_name: string = pageSchema?.options?.table_name;

    /*
     * Fetch paginated data from the dashboard table in batches of 1000 items per page.
     * The total number of iterations is determined by dividing the total quantity of the items by 1000 and rounding up.
     * Math.ceil - always rounds up and returns the smallest integer greater than or equal to a given number.
     */
    for (let index = 1; index <= Math.ceil(total / ITEMS_PER_PAGE); index++) {
      const response = await client.getDashboardTableData({
        business: businessName,
        dashboard_name,
        table_name,
        ...filter,
        lines_per_page: ITEMS_PER_PAGE,
        page_number: index,
        query: searchField,
      });
      data = data.concat(response.data);
    }
    
    object_type = customExportFilename || `${dashboard_name}_${table_name}_data`;
  } else if (Array.isArray(customExportPath)) {
    // Sequentially process each path
    for (const path of customExportPath) {
      const response = await client.getCustomListsAPI({
        list_name: path,
        query: searchField,
        ...filter,
        lines_per_page: total,
      })();
      data = data.concat(response.data);
      object_type = 'ALL_OBJECTS';
    }
  } else {
    const response = await client.getCustomListsAPI({
      list_name: customExportPath || list_name,
      query: searchField,
      ...filter,
      lines_per_page: total,
    })();
    data = response.data;
    object_type = response.object_type;
  }

  const workerMaker = (type, arg, options = {}) => {
    if (window.Worker) {
      worker.postMessage({ type, arg, options });
    }
  };

  workerMaker('csvFormat', data, {
    headerKeys: customHeaderExportKeys,
    extraColumns,
  });

  worker.onmessage = (e) => {
    const response = e.data;
    const data = response.data;
    const type = response.type;

    switch (type) {
      case 'formatted':
        downloadFile(data, `${object_type || 'export'}.csv`);
        message.success(t('messages.fileDownloaded'));
        break;
      case 'error':
        message.error(t('errors.somethingWentWrong'));
        break;
      default:
        console.log('Unexpected behavior with type:', type);
        break;
    }

    if (typeof onExportComplete === 'function') onExportComplete(false);
  };
};

export default exportAllItems;
